<template>
  <div>
    <router-view />
  </div>
</template>

<style>
/*****************skeltor loader css */

.error-msg{
  color: red !important;
  font-weight: 500;
}
.page-item.active .page-link {
    background-color: #171b2e !important;
    border-color: rgba(177, 202, 231, 0.3) !important;
}
.page-link {
    color: #fff !important;
    text-decoration: none;
        border: 1px solid rgba(177, 202, 231, 0.3) !important;
    background: #213043 !important;
}
.page-link:focus {
    box-shadow: none !important;
}

.vue-skeletor {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.12);
}


.vue-skeletor:not(.vue-skeletor--shimmerless):after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(37, 22, 22, 0));
    animation: shimmer 1.5s infinite;
    content: '';
}
.single-dropdown input{
  background: #39495c !important;
    background-size: 16px 12px !important;
    border: 1px solid #39495c !important;
    min-height: 47px !important;
    border-radius: unset !important;
}
.vue-skeletor--rect,
.vue-skeletor--circle {
    display: block;
}

.vue-skeletor--circle {
    border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
    border-radius: 9999px;
}

.vue-skeletor--text {
    line-height: 1;
    display: inline-block;
    width: 100%;
    height: inherit;
    vertical-align: middle;
    top: -1px;
}
input.multiselect-search{
    background:var(--db-placeholder-bg);
    color: var(--db-white);
}
.multiselect, input.multiselect-tags-search{
    background: var(--db-placeholder-bg) !important;
    border: 0 !important;
    color: var(--db-black);

}
span.multiselect-single-label-text{
  color: var(--db-white) !important;
  font-size: 14px;
}
@keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

/*****************skeltor loader css */
/*************************** Typography CSS ***************************/
@import "./assets/css/typography.css";

/************************************************************************
                                Typography 
*************************************************************************/

:root {
  /*************************** Color Variables ***************************/
  --db-black: #161b2e; /*Sidebar color*/
  --db-dark-blue: #213043; /*Background color*/
  --db-blue: #26364d; /*Cards color*/
  --db-light-blue: #2b405b; /*Navbar color*/
  --db-light-blue-1: #405676; /*Navbar color*/
  --db-transparent: rgba(0 0 0 0); /*Transparent color*/
  --db-slot-dark:rgba(43, 64, 91, 0.26);  /*Slots Pages Bg*/
  --db-placeholder-bg:rgba(255, 255, 255, 0.1);

  --db-transparent-1: rgb(255 255 255 / 4%);
  --db-white: #fff;
  --db-off-white-1: rgba(255, 255, 255, 0.36);
  --db-off-white-2: rgba(255, 255, 255, 0.32);
  --db-off-white-3: rgba(255, 255, 255, 0.7);
  --db-off-white-4: rgba(255, 255, 255, 0.08);
  --db-sky: #0291e8;
  --db-red: #e3423a;
  --db-yellow: #FEC808;
  --db-light-red: #FFD5D2;
  --db-green: #1cb434;
  --db-light-green: #C1F9CA;
  --db-text-grey:#939393;

  /*************************** Font-Family Variables ***************************/
  --db-mons-light: Montserrat-Light;
  --db-mons-regular: Montserrat-Regular;
  --db-mons-medium: Montserrat-Medium;
  --db-pop-regular: Poppins-Regular;
  --db-pop-medium: Poppins-Medium;
  --db-roboto-regular: Roboto-Regular;
  --db-roboto-medium: Roboto-Medium;

  /*************************** Common CSS Variables ***************************/
  --db-border-radius: 5px;
  --db-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --db-shadow-slot:0px 2px 6px 1px rgba(0, 0, 0, 0.29);
  --db-border: 1px solid #405676;
  --db-shadow-placeholder:inset 5px -6px 4px rgba(123, 119, 119, 0.09), inset -4px 3px 5px rgba(0, 0, 0, 0.12);
  --db-inset-shadow: inset -4px -3px 9px rgba(255, 255, 255, 0.25), inset 7px 6px 11px rgba(0, 0, 0, 0.32);
  --db-shadow-btn:inset -7px -6px 4px rgba(255, 255, 255, 0.09), inset 7px 7px 5px #2386D7;
  --db-inset-shadow: 0px 0px 4px rgba(0, 0, 0, 0.31), inset -1px 2px 4px rgba(0, 0, 0, 0.4);
  --db-gradient: linear-gradient(94.77deg, #189ce4 4.87%, #1164d7 99.69%);
  --db-gradient-btn:linear-gradient(90.15deg, #1799E3 1.69%, #2677DB 98.89%);
  --db-grey-gradient: linear-gradient(45deg, transparent, #ffffff4a, transparent);
}

/*************************** Common CSS ***************************/
#app {
  font-family: var(--db-mons-regular);
  background-color: var(--db-dark-blue);
}


.table>:not(caption)>*>*{
  border-bottom: 0;
}




.text-db-grey{
  color:var(--db-off-white-1)
}




</style>
