import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
    path: '/',
    name: 'home-view',
    meta: { authonly: false },
    component: HomeView
},




/********************* Leader Group ***************************/

{
    path: '/team',
    name: 'TeamView',
    meta: {
        LeaderOnly: true,
        authonly: false
    },
    component: () =>
        import('../LeaderGroup/TeamView.vue')
},
{
    path: '/dashBoards',
    name: 'DashBoards',
    meta: {
        LeaderOnly: true,
        authonly: false
    },
    component: () =>
        import('../LeaderGroup/DashBoards.vue')
},
{
    path: '/staff-employees',
    name: 'GStaffEmployee',
    meta: {
        LeaderOnly: true,
        authonly: false
    },
    component: () =>
        import('../LeaderGroup/StaffEmployee.vue')
},
{
    path: '/profile-views',
    name: 'GProfileView',
    meta: {
        LeaderOnly: true,
        authonly: false
    },
    component: () =>
        import('../LeaderGroup/ProfileView.vue')
},
{
    path: '/group-screenshots',
    // alias: '/',
    name: 'GScreenShots',
    meta:{
        LeaderOnly: true,
        authonly: false
    },
    component: () =>
        import('../LeaderGroup/ScreenShots.vue')
},

//**************************Auth Pages**************************//
{
    path: '/forgot',
    name: 'ForgotPassword',
    meta: { authonly: false },
    component: () =>
        import('../views/ForgotPassword.vue')
},
{
    path: '/reset-password',
    name: 'SetPassword',
    meta: { authonly: false },
    component: () =>
        import('../views/SetPassword.vue')
},
{
    path: '/profile',
    name: 'ProfileView',
    meta: { authonly: true },
    component: () =>
        import('../views/ProfileView.vue')
},
{
    path: '/user-activity',
    name: 'UserActivityView',
    meta: { authonly: true },
    component: () =>
        import('../views/UserActivityView.vue')
},
{
    path: '/connect-verify',
    name: 'RedirectView',
    meta: { authonly: true },
    component: () =>
        import('../views/RedirectView.vue')
},
//**************************Dashboard Page**************************//
{
    path: '/dashboard',
    // alias: '/',
    name: 'DashBoard',
    meta: { authonly: true },
    component: () =>
        import('../views/DashBoard.vue')
},
{
    path: '/screenshots',
    // alias: '/',
    name: 'ScreenShots',
    meta: { authonly: true },
    component: () =>
        import('../views/ScreenShots.vue')
},
//**************************Staff/Employee Page**************************//
{
    path: '/staff_employee',
    name: 'StaffEmployee',
    meta: { authonly: true },
    component: () =>
        import('../views/employee_staff/StaffEmployee.vue')
},

{
    path: '/staff_employee/edit',
    name: 'StaffEmployeeEdit',
    meta: { authonly: true },
    component: () =>
        import('../views/employee_staff/StaffEmployeeEdit.vue')
},
//**************************Add Staff Member Page**************************//
{
    path: '/add_staff',
    name: 'AddStaffMember',
    meta: { authonly: true },
    component: () =>
        import('../views/AddStaffMember.vue')
},
//**************************Setting Page**************************//
{
    path: '/setting',
    name: 'SettingsPage',
    meta: { authonly: true },
    component: () =>
        import('../views/settings/SettingsPage.vue')
},
{
    path: '/setting/general',
    name: 'GeneralSettings',
    meta: { authonly: true },
    component: () =>
        import('../views/settings/GeneralSettings.vue')
},
{
    path: '/setting/time',
    name: 'TimeSettings',
    meta: { authonly: true },
    component: () =>
        import('../views/settings/TimeSettings.vue')
},
//**************************Staff Page**************************//
{
    path: '/staff',
    name: 'SingleStaff',
    meta: { authonly: true },
    component: () =>
        import('../views/staff/SingleStaff.vue')
},
{
    path: '/staff/department',
    name: 'DepartmentSettings',
    meta: { authonly: true },
    component: () =>
        import('../views/staff/DepartmentSettings.vue')
},
{
    path: '/staff/add_department',
    name: 'AddDepartment',
    meta: { authonly: true },
    component: () =>
        import('../views/staff/AddDepartment.vue')
},
{
    path: '/staff/department/edit',
    name: 'DepartmentSettingsEdit',
    meta: { authonly: true },
    component: () =>
        import('../views/staff/DepartmentSettingsEdit.vue')
},

{
    path: '/staff/settings',
    name: 'StaffSettings',
    meta: { authonly: true },
    component: () =>
        import('../views/staff/StaffSettings.vue')
},
//**************************User Type Page**************************//
{
    path: '/user_type',
    name: 'UserType',
    meta: { authonly: true },
    component: () =>
        import('../views/User/UserType.vue')
},
{
    path: '/user_type/edit',
    name: 'EditUserType',
    meta: { authonly: true },
    component: () =>
        import('../views/User/EditUserType.vue')
},
{
    path: '/user_type/add',
    name: 'AddUserType',
    meta: { authonly: true },
    component: () =>
        import('../views/User/AddUserType.vue')
},
//**************************Locations Page**************************//
{
    path: '/country',
    name: 'CounTry',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/Country.vue')
},
{
    path: '/country/edit',
    name: 'EditCountry',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/EditCountry.vue')
},
{
    path: '/country/add',
    name: 'AddCountry',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/AddCountry.vue')
},
{
    path: '/state',
    name: 'StateView',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/State.vue')
},
{
    path: '/state/edit',
    name: 'EditState',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/EditState.vue')
},
{
    path: '/state/add',
    name: 'AddState',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/AddState.vue')
},
{
    path: '/city',
    name: 'CityView',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/City.vue')
},
{
    path: '/city/edit',
    name: 'EditCity',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/EditCity.vue')
},
{
    path: '/city/add',
    name: 'AddCity',
    meta: { authonly: true },
    component: () =>
        import('../views/Locations/AddCity.vue')
},
//**************************Storage Page**************************//

{
    path: '/storage',
    name: 'StoragePage',
    meta: { authonly: true },
    component: () =>
        import('../views/storage/StoragePage.vue')
},
//**************************Storage Page**************************//

{
    path: '/group',
    name: 'GroupView',
    meta: { authonly: true },
    component: () =>
        import('../views/Group/GroupView.vue')
},
{
    path: '/mac-bindings',
    name: 'MacBindings',
    meta: { authonly: true },
    component: () =>
        import('../views/Group/Mac_binding.vue')
},

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    // var Token = false;
    var Token = localStorage.getItem("token");

    let metaData = localStorage.getItem('metaDatA') ? atob(localStorage.getItem('metaDatA')) : '';
    let IsLeader = metaData ? JSON.parse(metaData) : false;
    IsLeader = IsLeader.is_leader
    // console.log({ IsLeader, mmm: to.meta.authonly  } , to.meta.LeaderOnly , IsLeader , to)

    if (to.meta.authonly && !IsLeader) {
        // console.log("admin")
        Token ? next() : next({ path: "/" });
    } else if (to.meta.LeaderOnly && IsLeader) {
        // console.log(IsLeader)
        // console.log("leader")

        Token ? next() : next({ path: '/' })

    } else {

        // console.log("login")

        Token ? IsLeader ? next({ path: '/team' }) : next({ path: "/dashboard" }) : next();

    }
})
export default router