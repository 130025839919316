import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


//Boostrap CSS
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'



import 'v-calendar/dist/style.css';



import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Skeletor } from 'vue-skeletor';
import "@vueform/multiselect/themes/default.css";

import { faLock, faUser, faKey, faEye, faEyeSlash, faChevronRight, faTrashCan, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

library.add(faLock, faUser, faKey, faEye, faEyeSlash, faChevronRight, faTrashCan, faPenToSquare);





import VCalendar from 'v-calendar';




const mixins= {
   
   
}

createApp(App).component(Skeletor.name, Skeletor).component("font-awesome-icon", FontAwesomeIcon).mixin(mixins).use(store).use(router).use(VCalendar, {}).use(VueSweetalert2).use(VueAxios, axios).mount('#app')